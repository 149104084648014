import {useState, Dispatch, SetStateAction} from "react";
import {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {useAuth0, Auth0ContextInterface, User} from "@auth0/auth0-react";
import _ from "lodash";
import api, {get} from "../../api";
import slackCall from "./SlackCall";

interface CallHandler{
    loading:boolean
    error:AxiosError|null
}

interface CallHandlerReturn{
    loading:boolean
    get:(url:string) => Promise<AxiosResponse|AxiosError>
    error:AxiosError|null
    call:(config:AxiosRequestConfig) => Promise<AxiosError|AxiosResponse|undefined>
}

/**
 * useCall
 * @return {CallHandlerReturn}
 */
export default function useCall():CallHandlerReturn {
    const {user}:Auth0ContextInterface<User>= useAuth0();
    const [state, setState]:[CallHandler, Dispatch<SetStateAction<CallHandler>>]=useState<CallHandler>({error: null, loading: false});

    /**
     * getHelper
     * @param {string} url
     * @return {Promise<AxiosResponse|AxiosError>}
     */
    const getHelper = async (url:string):Promise<AxiosResponse|AxiosError> => {
        setState((prev:CallHandler) => ({...prev, loading: true}));
        // exit getHelper on error
        if (state.error) {
            setState((prev:CallHandler) => ({...prev, loading: false}));
            return state.error;
        }
        const res = get(url)
            .then((response:AxiosResponse) => {
                // set loading false
                setState((prev:CallHandler) => ({...prev, loading: false}));
                return response;
            })
            .catch((error:AxiosError) => {
                slackCall(error, user as User);
                setState({error, loading: false});
                return error;
            });
        return res;
    };

    /**
     * call
     * @param {AxiosRequestConfig} config
     * @return {Promise<AxiosError|AxiosResponse|undefined>}
     */
    const call= async (config:AxiosRequestConfig):Promise<AxiosError|AxiosResponse|undefined> => {
        if (config===undefined) return undefined;
        setState((prev:CallHandler) => ({...prev, loading: true}));
        return api(config)
            .then((res:AxiosResponse) => {
                setState((prev:CallHandler) => ({...prev, loading: false}));
                return res;
            })
            .catch((error:AxiosError) => {
                slackCall(error, user as User);
                setState({error, loading: false});
                return error;
            });
    };

    return {loading: state.loading, get: getHelper, error: state.error, call};
}
