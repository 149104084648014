import {useEffect, useCallback} from "react";
import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";
import {LocalStorageCache, User} from "@auth0/auth0-react";
import {env} from "../../config";
import api from "../../api";
import slackCall from "./SlackCall";

interface Props{
    user:User|undefined,
    logout:any
}

/**
 * getAuth0StorageKey
 * @param {LocalStorageCache} auth0Storage
 * @return {string|undefined}
 */
const getAuth0StorageKey=(auth0Storage:LocalStorageCache):string|undefined => auth0Storage.allKeys().find((k:any) => k.includes(`@@auth0spajs@@::${env.REACT_APP_AUTH0_CLIENT_ID}::${env.REACT_APP_AUTH0_AUDIENCE}`));

/**
 * useAuth0TokenRecovery
 * @return {void}
 */
const useAuth0TokenRecovery=(props:Props):void => {
    const {user, logout} = props;

    /**
     * resolveToken
     * @return {Promise<AxiosError>}
     */
    const resolveToken=useCallback(async ():Promise<AxiosError> => {
        // auth0 storage access
        const auth0Storage=new LocalStorageCache();
        // resolving auth0 user session key
        const key=getAuth0StorageKey(auth0Storage);
        // escape if key is undefined
        if (key===undefined) return {status: 401, message: "Unable to Resolve Auth0 LocalStorageCache key", response: {data: {error: "undefined_storage", storage: auth0Storage}}} as AxiosError;
        // resolve user session object
        const auth0StorageUserSession:any=auth0Storage.get(key);

        // construct resquest config
        const request:AxiosRequestConfig={
            method: "POST",
            url: `https://${env.REACT_APP_AUTH0_DOMAIN}/oauth/token`,
            headers: {"content-type": "application/x-www-form-urlencoded"},
            data: new URLSearchParams({
                grant_type: "refresh_token",
                client_id: env.REACT_APP_AUTH0_CLIENT_ID,
                refresh_token: auth0StorageUserSession.body.refresh_token,
            }),
        };

        // make api call
        return axios(request)
            .then((response:AxiosResponse) => {
                // update axios global Authorization header
                api.defaults.headers.common.Authorization=`Bearer ${response?.data?.access_token}`;
                // update user session
                localStorage.setItem(key, JSON.stringify({...auth0StorageUserSession, body: {...auth0StorageUserSession.body, ...response.data}}));
                return {status: response.status, message: "Token Resolved Successfully!", response: {data: {access_token: response?.data?.access_token}}} as AxiosError;
            })
            .catch((error:AxiosError) => error);
    }, []);

    useEffect(() => {
        // intercepting on incoming response
        const interceptorId=api.interceptors.response.use(
            (response:AxiosResponse) => response,
            async (error:AxiosError) => {
                if (error?.response?.status===403) {
                    // resolving new access_token
                    const tokenResponse:AxiosError=await resolveToken();

                    // terminate from token reselution AND logout user when inactivity lifetime value exhausted
                    if (["invalid_grant", "undefined_storage"].includes((tokenResponse.response as AxiosResponse)?.data.error)) {
                        api.interceptors.response.eject(interceptorId);
                        logout({logoutParams: {returnTo: `${window.location.origin}/?signout=true&inactive=true`}});
                        return Promise.reject(error);
                    }

                    // terminate from token reselution (AxiosError)
                    if (tokenResponse.status!==200) return Promise.reject(tokenResponse);

                    // re-attempt same failed api call
                    const newRequest:AxiosRequestConfig={...error.config, headers: {...error.config?.headers, Authorization: `Bearer ${(tokenResponse?.response?.data as any).access_token}`}};
                    return api(newRequest)
                        .then((r:AxiosResponse) => r)
                        .catch((e:AxiosError) => {
                            slackCall(e, user as User);
                            return Promise.reject(e);
                        });
                }
                return Promise.reject(error);
            },
        );

        // ejecting on hook unmounting
        return () => {
            api.interceptors.response.eject(interceptorId);
        };
    }, [resolveToken, user, logout]);
};

export default useAuth0TokenRecovery;
