import React from "react";
import {CardContent, useTheme, Theme, Typography, CardActions, Card as CardBase, Button, Grid, Box, SvgIconTypeMap, SxProps, Stack, Chip, Link, LinkProps} from "@mui/material";
import {
    Assessment as AssessmentIcon,
    TrendingUpOutlined as TrendingUpIcon,
    CorporateFareOutlined as CorporateFareIcon,
    HubOutlined as HubIcon,
    BoltOutlined as BoltIcon,
    ArrowForward,
} from "@mui/icons-material";
import {OverridableComponent} from "@mui/material/OverridableComponent";
import {useNavigate} from "react-router-dom";
import {useAuth0, Auth0ContextInterface, User} from "@auth0/auth0-react";
import buildingsImage from "../../static/banner_buildings.jpg";
import {APP_PATHS, WHITE, BLUE_LIGHT, GREY, DARK_BLUE} from "../../config";
import WithSpace from "./WithSpace";
import OverLineTitle from "./OverLineTitle";

interface CardProps {
    title:string
    description:string
    buttonText:string
    buttonOnClick:() => void
    backgroundColor?:string
    icon?:OverridableComponent<SvgIconTypeMap<object, "svg">>
    disabled?:boolean
}

interface CardData extends Omit<CardProps, "buttonOnClick">{
    path:string // path to navigate to when the button is clicked. eg) () => navigate(path)
    overLineTitle?:string
    chipLabel?:string
    chipColor?:"primary"|"secondary"|"default"|"error"|"info"|"success"|"warning"
}

interface QuickLinkProps {
    title: string;
    description: string;
    icon: OverridableComponent<SvgIconTypeMap<object, "svg">>;
    path: string;
}

// defined for the future extensions
interface QuickLinkData extends QuickLinkProps {
    // no additional properties
}

interface BannerProps{
    user:User|undefined
}

const cardData: CardData[] = [
    {
        title: "Data Export",
        overLineTitle: "Data Export",
        description: "Extract the most important data with some customization options to tailor the report to your needs.",
        buttonText: "Start Data Export",
        path: APP_PATHS.BASIC_DATA_EXPORT,
        backgroundColor: WHITE,
        icon: TrendingUpIcon,
        chipLabel: "Pilot Version",
        chipColor: "primary",
    },
    {
        title: "Advanced Data Export",
        overLineTitle: "Future Release",
        description: "Refine your energy consumption and impact data with extensive customization options.",
        buttonText: "Start Advanced Data Export",
        path: APP_PATHS.HOME,
        backgroundColor: WHITE,
        icon: AssessmentIcon,
        disabled: true,
        chipLabel: "Coming Soon!",
        chipColor: "info",
    },
    {
        title: "Need Support?",
        description: "Have a question or need assistance? Review our resources or contact us for any inquiries you may have!",
        buttonText: "Get Help",
        path: APP_PATHS.FAQ,
        backgroundColor: BLUE_LIGHT,
    },
];

const quickLinkData: QuickLinkData[] = [
    {
        title: "DEM Salesforce",
        description: "Looking to track projects? Visit the DEM Salesforce Portal.",
        icon: CorporateFareIcon,
        path: APP_PATHS.DEM_SALESFORCE,
    },
    {
        title: "Agency Portal",
        description: "Looking for project details? Visit the Agency Portal for detailed reports.",
        icon: HubIcon,
        path: APP_PATHS.AGENCY_PORTAL,
    },
    {
        title: "Energy Tools",
        description: "Looking for tools? Visit NYC Energy Tools to access all tools.",
        icon: BoltIcon,
        path: APP_PATHS.ENERGY_TOOLS,
    },
];

/**
* IconCircleBackground
* @description Renders a circular background for an icon.
* @param {{children: React.ReactElement, sx: SxProps}} props
* @return {React.ReactElement}
*/
function IconCircleBackground(props: {children: React.ReactElement, sx: SxProps}):React.ReactElement {
    const theme:Theme=useTheme();
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 70,
            height: 70,
            backgroundColor: WHITE,
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: "50%",
            ...props.sx,
        }}
        >
            {props.children}
        </Box>
    );
}

/**
 * Card
 * @param {CardProps} props
 * @return {React.ReactElement}
 */
function Card(props:CardProps):React.ReactElement {
    const theme:Theme=useTheme();
    return (
        <CardBase sx={{
            backgroundColor: props.backgroundColor,
            boxShadow: "rgb(90 113 142 / 20%) 0px 3px 4px 0px",
            padding: "31px 27px",
            borderRadius: "12px",
            border: `1px solid ${theme.palette.primary.main}`}}
        >
            <CardContent sx={{padding: "0"}}>
                <Grid container spacing={3} paddingBottom="30px">
                    {/* icon */}
                    {props.icon&&(
                        <Grid item display={{xs: "none", lg: "block"}}>
                            <IconCircleBackground sx={{border: `1px solid ${props.disabled?GREY:theme.palette.primary.main}`}}>
                                <props.icon sx={{fontSize: 33, color: props.disabled?GREY:undefined}} />
                            </IconCircleBackground>
                        </Grid>
                    )}
                    <Grid xs item>
                        {/* Title */}
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            color={props.disabled?GREY:undefined}
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            {props.title}
                        </Typography>
                        {/* Description */}
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "3",
                                WebkitBoxOrient: "vertical",
                            }}
                        >
                            {props.description}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions sx={{padding: 0}}>
                {/* Button */}
                <Button
                    disabled={props.disabled}
                    onClick={props.buttonOnClick}
                    fullWidth
                    size="large"
                    variant="outlined"
                    sx={{
                        padding: "8px 22px",
                        border: `1px solid ${props.disabled?GREY:theme.palette.primary.main}!important`,
                        color: props.disabled?`${GREY}!important`:undefined,
                        textTransform: "capitalize",
                        fontWeight: "medium",
                        // hide text on overflow
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                    }}
                >
                    {props.buttonText}
                </Button>
            </CardActions>
        </CardBase>
    );
}

/**
 * QuickLink
 * @param {QuickLinkProps} props
 * @return {React.ReactElement}
 */
function QuickLink(props:QuickLinkProps):React.ReactElement {
    const theme:Theme=useTheme();
    // Common properties for both links
    const commonLinkProps: LinkProps = {
        display: "block",
        target: "_blank",
        href: props.path,
        underline: "hover",
    };

    return (
        <Grid container spacing={3}>
            {/* icon */}
            {props.icon&&(
                <Grid item display={{xs: "none", lg: "block"}}>
                    <IconCircleBackground sx={{border: `1px solid ${theme.palette.primary.main}`}}>
                        <props.icon sx={{fontSize: 33}} />
                    </IconCircleBackground>
                </Grid>
            )}
            <Grid xs item>
                {/* Title */}
                <Link {...commonLinkProps} variant="h6" color="text.primary" fontWeight="bold" display="flex" alignItems="center" gap={1}>
                    {props.title}
                    <ArrowForward sx={{color: DARK_BLUE, display: {xs: "block", lg: "none"}}} />
                </Link>
                {/* Description */}
                <Link {...commonLinkProps} variant="body1" color="text.secondary">{props.description}</Link>
            </Grid>
        </Grid>
    );
}

/**
 * Banner
 * @todo Adjust the image to extend fully to the edges, compensating for global padding(container).
 * @param {BannerProps} props
 * @return {React.ReactElement}
 */
function Banner(props: BannerProps):React.ReactElement {
    return (
        <Stack
            height="205px"
            justifyContent="center"
            gap="8px"
            boxShadow="inset 0 0 0 50vw rgba(24, 42, 51, 0.4)" // Overlay for image
            color={WHITE} // Text
            sx={{
                // Image
                backgroundImage: `url(${buildingsImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center 68%",
            }}
        >
            <WithSpace>
                <Typography fontSize="48px" lineHeight="56.02px" fontWeight="700">
                    {props.user?.name?`Hi, ${props.user?.name}.`:"Hi."}
                </Typography>
                <Typography fontSize="24px" lineHeight="32.02px">
                    Welcome to your energy portal.
                </Typography>
            </WithSpace>
        </Stack>
    );
}

/**
 * InsightsOverview
 * @return {React.ReactElement}
 */
function InsightsOverview():React.ReactElement {
    return (
        <Grid container spacing="36px">
            <Grid item display={{xs: "none", md: "block"}} md={6}>
                <Typography variant="h3" color="text.primary" lineHeight="51.52px">
                    Empower Your Energy Insights: Retrieve, Download, and Analyze Data to Optimize Usage and Drive Emissions Reductions.
                </Typography>
            </Grid>
            <Grid item display={{xs: "none", sm: "block"}} xs={12} md={6}>
                <Typography variant="h6" fontWeight={400} color="text.secondary" lineHeight="32px">
                    Using this platform, you can retrieve, download, and analyze energy consumption data.
                    Regardless of what your data needs are, this platform is designed to empower you with insights to optimize energy usage and drive carbon-reduction initiatives.
                    Start exploring now by clicking into the Data Export page to retrieve energy data for meters, properties, or agencies.
                    <br />
                    <br />
                    Need more customization? Stay tuned for new releases!
                </Typography>
            </Grid>
        </Grid>
    );
}

/**
 * CardsSection
 * @note This component depends on the Card component and cardData array.
 * @return {React.ReactElement}
 */
function CardsSection():React.ReactElement {
    const navigate = useNavigate();

    return (
        <Grid container spacing={3}>
            {cardData.map((card: CardData) => (
                <Grid item key={card.title} xs={12} md={4} sx={{display: "flex", alignSelf: "flex-end", flexDirection: "column", gap: "12px"}}>
                    <Box sx={{display: "flex", alignItems: "center", gap: "8px"}}>
                        {card.overLineTitle && <OverLineTitle title={card.overLineTitle} />}
                        {card.chipLabel && (
                            <Chip
                                label={card.chipLabel}
                                variant="outlined"
                                size="small"
                                color={card.chipColor}
                                sx={{marginTop: "3px", borderColor: `${card.chipColor}.main`}}
                            />
                        )}
                    </Box>
                    <Card {...card} buttonOnClick={() => navigate(card.path, {state: {from: window.location.pathname}})} />
                </Grid>
            ))}
        </Grid>
    );
}

/**
 * QuickLinksSection
 * @note This component depends on the QuickLink component and quickLinkData array.
 * @return {React.ReactElement}
 */
function QuickLinksSection():React.ReactElement {
    return (
        <Box sx={{display: "flex", flexDirection: "column", gap: 3}}>
            <OverLineTitle title="Quick Links" />
            <Grid container spacing={4}>
                {quickLinkData.map((link) => (
                    <Grid item key={link.title} xs={12} md={4}>
                        <QuickLink {...link} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

/**
 * PostSplash
 * @return {React.ReactElement}
 */
function PostSplash():React.ReactElement {
    const {user}:Auth0ContextInterface<User>= useAuth0();

    return (
        <Box display="flex" flexDirection="column" gap="64px">
            <Banner user={user} />
            <WithSpace>
                <Stack direction="column" gap="64px" paddingBottom="128px">
                    <InsightsOverview />
                    <CardsSection />
                    <QuickLinksSection />
                </Stack>
            </WithSpace>
        </Box>
    );
}

export default PostSplash;
