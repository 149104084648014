import React, {useEffect, useRef, useState, ReactElement, ReactNode, useCallback} from "react";
import {CheckBox, CheckBoxOutlineBlank, ArrowDropDown, ArrowDropUp, IndeterminateCheckBoxOutlined, Close} from "@mui/icons-material";
import * as yup from "yup";
import {UseFormRegister, FieldValues, Controller, useWatch, UseWatchProps, UseFormReturn} from "react-hook-form";
import {ClickAwayListener} from "@mui/base";
import {grey} from "@mui/material/colors";
import lo from "lodash";
import {
    Button as ButtonBase,
    ButtonProps as ButtonPropsBase,
    Autocomplete as AutocompleteBase,
    TextField as TextFieldBase,
    TextFieldProps as TextFieldPropsBase,
    Typography,
    Box,
    InputAdornment,
    Chip as ChipBase,
    ChipProps as ChipPropsBase,
    Tooltip as TooltipBase,
    TooltipProps,
    Checkbox,
    IconButton,
    useTheme,
    Theme,
    CircularProgress,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList,
    FormGroup,
    AutocompleteRenderGroupParams,
    Divider,
    CheckboxProps,
    SxProps,
} from "@mui/material";
import {useContext, ContextType} from "./Context";
import {DialogType} from "../../types";

export const OPTION_SEPARATOR="-_-";
export const ITEM_META="_meta";
const OPTIONS_SIZE={limit: 50, message: "Too many results. Please add more details to narrow responses."};
const DROPDOWN_BORDER_RADIUS="8px";

const DROPDOWN_CELL_STYLE = {
    padding: "0px 8px !important",
    margin: "4px 0px !important",
    borderRight: "1px solid #0000001F",
};

const ASYNC_ROW_STYLE = {
    display: "flex",
    height: "32px",
    fontSize: "14px",
};

interface Register {
    register?:UseFormRegister<FieldValues>
}

type COnChange=(...event: any[]) => void;

interface InputError{
    text:string
    status:boolean
}

export interface OptionHeader {
    key:string
    label:string
    width?:number
    altOptionHeader?:OptionHeader // used when optionHeader is conflicting with selector
}

export interface Selector{
    label:string
    key:string
    yup:(value:any) => Promise<yup.ValidationError|boolean>
    width?:number
    tooltip?:string
}

interface SelectorButtonProps {
    options:Selector[]
    onChange:(option:Selector) => void
    disabled?:boolean
}

interface EndAdornmentType{
    icon:any
    onClick:(args:React.MouseEvent|React.KeyboardEvent, value:any, selector?:Selector) => Promise<void>
}

interface Dialog{
    type:DialogType
    watch?:string[]
}

export interface AutocompleteOptions{
    options:string[]|any[]
    disabled?:boolean
    selectors?:Selector[]
    multiple?:boolean
    endAdornment?:EndAdornmentType // used to identify async search varient
    readOnly?:boolean
    helperText?:string
    optionsHeader?:OptionHeader[]
    selectAllLabel?:string
    dialog?:Dialog
}

export interface Field{
    key:string
    label:string
    yup:yup.Schema
    type:"autocomplete"
    autocompleteOptions?:AutocompleteOptions
}

interface ButtonProps extends ButtonPropsBase {
    label?:string
}

type TextFieldProps=TextFieldPropsBase & Register;

interface ControlledField {
    field:Field
    formReturn:UseFormReturn<FieldValues>
    loading?:boolean
    onSelection?:(data:any) => void
}

interface DropdownRowProps {
    params:any
    fieldKey:string
    columns?:OptionHeader[]
    option?:any
    sx?:SxProps
    checkBoxProps?:CheckboxProps
    dialog?:Dialog
    formWatch?:any
    context?:ContextType
}

interface DropdownCellProps {
    width:number|undefined
    label:string
}

/**
 * resolveWatchKey
 * function "watch" size of incomming Fields using watch arg;
 * if any Field size > 0; it returns true otherwise checks watch arg;
 * @param {any} formWatch
 * @param {string[]|undefined} watch
 * @return {boolean}
 */
const resolveWatchKey=(formWatch:any, watch:string[]|undefined):boolean => {
    const isEmpty=(watch
        ?(
            watch
                .reduce(
                    (a:any, k:string) => ([
                        ...a,
                        (Array.isArray(formWatch[k]) ? formWatch[k].length>0 : null),
                    ]),
                    [],
                )
                .filter((v:boolean|null) => v===true).length>0
        )
        :undefined);
    return (isEmpty || !watch);
};

/**
 * Button
 * @param {ButtonProps} props
 * @return {ReactElement}
 */
function Button(props:ButtonProps):ReactElement {
    return <ButtonBase sx={{width: "100%"}} {...props}>{props.label}</ButtonBase>;
}

/**
 * Chip
 * @param {ChipPropsBase} props
 * @return {ReactElement}
 */
function Chip(props:ChipPropsBase):ReactElement {
    return <ChipBase {...props} />;
}

/**
 * TextField
 * @param {TextFieldProps} props
 * @return {ReactElement}
 */
function TextField(props:TextFieldProps):ReactElement {
    return <TextFieldBase {...props} sx={{width: "100%"}} />;
}

/**
 * Tooltip
 * @param {TooltipProps} props
 * @return {ReactElement}
 */
function Tooltip(props:TooltipProps):ReactElement {
    return (
        <TooltipBase
            {...props}
            disableInteractive
            slotProps={{popper: {modifiers: [{name: "offset", options: {offset: [0, -14]}}]}}}
        />
    );
}

/**
 * SelectorButton
 * @param {SelectorButtonProps} props
 * @return {ReactElement}
 */
function SelectorButton(props:SelectorButtonProps):ReactElement {
    const {options, onChange} = props;
    const [selectorOpen, setSelectorOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [selectedOption, setSelectedOption] = useState(options[0]);

    /**
     * handleMenuItemClick
     * @param {Selector} option
     * @return {void}
     */
    const handleMenuItemClick=(option:Selector):void => {
        onChange(option);
        setSelectedOption(option);
        setSelectorOpen(false);
    };

    /**
     * handleToggle
     * @return {void}
     */
    const handleToggle=():void => { setSelectorOpen((prevOpen) => !prevOpen); };

    /**
     * handleClose
     * @param {Event} args
     * @return {void}
     */
    const handleClose = (args:Event):void => {
        if (anchorRef.current && anchorRef.current.contains(args.target as HTMLElement)) return;
        setSelectorOpen(false);
    };
    return (
        <Box>
            <ButtonBase
                ref={anchorRef}
                disabled={props.disabled}
                variant="outlined"
                size="large"
                onClick={handleToggle}
                sx={{
                    justifyContent: "space-between",
                    padding: "10px 8px",
                    height: "56px",
                    width: "150px",
                    borderColor: grey[400],
                    borderRight: "0px",
                    borderTopLeftRadius: DROPDOWN_BORDER_RADIUS,
                    borderBottomLeftRadius: DROPDOWN_BORDER_RADIUS,
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0",
                    "&:hover": {borderRight: "0px", borderColor: grey[800]},
                    "&.Mui-disabled": {borderColor: "rgba(0, 0, 0, 0.26)", borderRight: "0px"},
                }}
            >
                <Tooltip title={selectedOption.tooltip} placement="top">
                    <Typography sx={{
                        textTransform: "none",
                        fontSize: "16px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        color: props.disabled ? grey[500] : grey[700]}}
                    >
                        {selectedOption.label}
                    </Typography>
                </Tooltip>
                <ArrowDropDown sx={{color: props.disabled? grey[400] : grey[600]}} />
            </ButtonBase>
            <Popper
                placement="bottom-start"
                sx={{zIndex: 1, width: "150px"}}
                open={selectorOpen}
                anchorEl={anchorRef.current}
                transition
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem>
                                    {options.map((option:Selector) => (
                                        <Tooltip
                                            key={option.key}
                                            title={option.tooltip}
                                        >
                                            <MenuItem
                                                key={option.key}
                                                selected={option === selectedOption}
                                                onClick={() => handleMenuItemClick(option)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        </Tooltip>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
}

/**
 * DropdownCell
 * @param {DropdownCellProps} props
 * @return {ReactElement}
 */
function DropdownCell(props:DropdownCellProps): ReactElement {
    const {width, label} = props;
    const rowRef = useRef<HTMLSpanElement>(null);
    const [isTruncated, setIsTruncated] = useState(false);

    useEffect(() => {
        const el = rowRef.current;
        if (el) {
        // Check if content is overflowing/truncated
            setIsTruncated(el.scrollWidth > el.clientWidth);
        }
    }, [label, width]);
    // dependencies: recalculate when label or dimensions change

    const content = (
        <Box
            component="span"
            ref={rowRef}
            sx={{
                ...DROPDOWN_CELL_STYLE,
                width: `${width}%`,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            {label}
        </Box>
    );

    return isTruncated ? (<Tooltip title={label} placement="top">{content}</Tooltip>) : (content);
}

/**
 * DropdownRow
 * @param {DropdownRowProps} props
 * @return {ReactElement}
 */
function DropdownRow(props:DropdownRowProps): ReactElement {
    const {columns, params: {key, ...rest}, option, checkBoxProps, sx, dialog, formWatch, fieldKey, context} = props;

    /**
     * onItemClick
     * escape on self-click / same-item-click
     * currentTarget is lost due to dialog triggering therefore re-setting it from target
     * @param {React.MouseEvent} args
     */
    const onItemClick=useCallback((args:React.MouseEvent):void => {
        if (context && rest.onClick && dialog?.type && formWatch[fieldKey] && resolveWatchKey(formWatch, dialog?.watch) && (args.target as any).textContent!==formWatch[fieldKey]) {
            context.setState({
                ...lo.cloneDeep(context.state),
                dialog: dialog.type,
                to: () => rest.onClick({...args, currentTarget: args.target}),
            });
        } else if (rest.onClick) rest.onClick(args);
    }, [formWatch, context, dialog, fieldKey, rest]);

    return (
        <Box sx={sx} component="li" {...rest} onClick={onItemClick}>
            {checkBoxProps&&(
                <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    indeterminateIcon={<IndeterminateCheckBoxOutlined fontSize="small" />}
                    {...checkBoxProps}
                />
            )}
            {columns?.length
                ? columns.map((column) => (
                    <DropdownCell
                        width={column.width}
                        key={column.key}
                        label={option ? option[column.key] : column.label}
                    />
                ))
                : option}
        </Box>
    );
}

/**
 * formatHeaders
 * @param {Selector|undefined} selector
 * @param {OptionHeader[]|undefined} optionsHeader
 */
const formatHeaders = (selector:Selector|undefined, optionsHeader: OptionHeader[]|undefined): OptionHeader[] => {
    if (!selector && !optionsHeader) return [];
    if (!selector) return optionsHeader as OptionHeader[];
    if (!optionsHeader) return [selector];

    const headers: OptionHeader[] = optionsHeader.map((header: OptionHeader) : OptionHeader => {
        // if headers are overlapping and the alternative header is available, return the alternative header
        if (header.key === selector.key && header.altOptionHeader) return header.altOptionHeader;
        return header;
    });

    return [selector, ...headers];
};

/**
 * ControlledAutocomplete
 * @param {ControlledField} props
 * @return {ReactElement}
 */
function ControlledAutocomplete(props:ControlledField):ReactElement|null {
    const [open, setOpen]=useState(false);
    const [checkAll, setCheckAll]=useState(false);
    const [selector, setSelector]=useState<Selector|undefined>(props.field.autocompleteOptions?.selectors?.[0]);
    const [inputError, setInputError]=useState<InputError>({text: "", status: false});
    const [inputValue, setInputValue]=useState("");

    const {options, multiple, optionsHeader, endAdornment, selectAllLabel, helperText, disabled, readOnly, selectors, dialog}:AutocompleteOptions=props.field.autocompleteOptions as AutocompleteOptions;
    const {control, formState: {errors}, setValue}:UseFormReturn<FieldValues>=props.formReturn;
    const loading = open && props.loading;
    const isThreshold=endAdornment!==undefined && options.length>OPTIONS_SIZE.limit;
    const theme:Theme=useTheme();
    const formWatch:any=useWatch({control} as UseWatchProps<any>);
    const displayHeaders=formatHeaders(selector, optionsHeader);
    const context=useContext();

    // NOTE: async variant's setCheckAll UI resolution; Actual select all happens in renderGroup's onClick
    useEffect(() => {
        if (multiple && optionsHeader) {
            if (options && Array.isArray(formWatch[props.field.key]) && options.every((option:any) => formWatch[props.field.key].some((datum:any) => lo.isEqual(option, datum)))) {
                setCheckAll(true);
            } else setCheckAll(false);
        }
    }, [checkAll, formWatch, multiple, optionsHeader, options, props.field.key]);

    // NOTE -- Module Rules
    // Pattern "@@ERROR:_<DESCRIPTION>"
    // Escape when no AutocompleteOptions
    if (props.field.autocompleteOptions===undefined) throw new Error("@@ERROR: Missing AutocompleteOptions");
    // Escape when ASYNC and dialog
    if (dialog && endAdornment) throw new Error("@@ERROR: Async Dialog Configuration");
    // Escape when MULTI and dialog
    if (dialog && multiple) throw new Error("@@ERROR: Multi Dialog Configuration");
    // Escape when ASYNC requirements are not provided
    if (endAdornment && (!selector || !optionsHeader)) throw new Error("@@ERROR: Async Configuration. Pass Selector and OptionHeader.");
    // Escape when SYNC Selector passed. Non used
    if (!endAdornment && selector) throw new Error("@@ERROR: Sync Configuration. Remove Selector.");

    /**
     * renderGroup
     * @param {AutocompleteRenderGroupParams} params
     * @param {any} value
     * @param {COnChange} onControllerChange
     * @return {ReactElement}
     */
    const renderGroup=(params:AutocompleteRenderGroupParams, value: any, onControllerChange:COnChange):ReactNode => {
        const HEADER_STYLE = {fontWeight: "700", borderBottom: "1px solid #0000001F"};
        const {children, key} = params;
        /**
         * onCheckAllClick
         * Toggles selection of all items currently visible in the search result dropdown.
         *  - If "select all," add those items not yet selected.
         *  - If "unselect all," remove them from the selection.
         * @returns {void}
         */
        const onCheckAllClick=():void => {
            const searchResults = options;
            const selectedItems = value;

            // if "select all" is requested
            if (!checkAll) {
                const reversedSearchResults = [...searchResults].reverse();

                // filter out those from the reversed set that are NOT already selected.
                const newlySelectedItems = lo.differenceWith(reversedSearchResults, selectedItems, lo.isEqual);

                // combine the existing selection with the newly selected ones.
                const updatedSelection = [...selectedItems, ...newlySelectedItems];

                setCheckAll(true);
                props.onSelection?.(updatedSelection);
                onControllerChange(updatedSelection);
            } else { // if "unselect all" is requested
            // unselect all items currently displayed in the dropdown(search results) from the selection.
            // note: differenceWith is used due to mutual records between api query results and selected items.
                const remainingSelectedItems = lo.differenceWith(selectedItems, searchResults, lo.isEqual);

                setCheckAll(false);
                props.onSelection?.(remainingSelectedItems);
                onControllerChange(remainingSelectedItems);
            }
        };

        return (
            <Box key={key}>
                {optionsHeader&&(
                    <DropdownRow
                        columns={displayHeaders}
                        params={params}
                        checkBoxProps={
                            multiple
                                ?{indeterminate: checkAll?false:value.length>0, checked: checkAll, onClick: onCheckAllClick, sx: {...DROPDOWN_CELL_STYLE, borderRadius: 0}}
                                :undefined
                        }
                        sx={{...ASYNC_ROW_STYLE, ...HEADER_STYLE}}
                        fieldKey={props.field.key}
                    />
                )}
                {children}
            </Box>
        );
    };

    /**
     * renderOption
     * @param {any} renderOptionProps
     * @param {any} option
     * @param {any} checkBox
     * @return {ReactElement}
     */
    const renderOption=(renderOptionProps:any, option:any, checkBox:any):ReactElement => {
        const SELECT_ALL_STYLE = {paddingLeft: "0 !important", borderBottom: "1px solid #0000001F"};
        const OPTION_STYLE = {padding: "0px !important"};
        const {key} = renderOptionProps;

        return (
            <DropdownRow
                key={key}
                columns={displayHeaders}
                option={option}
                params={renderOptionProps}
                dialog={dialog}
                formWatch={formWatch}
                fieldKey={props.field.key}
                context={context}
                // SINGLE_ASYNC_OBJECT
                {...!multiple && endAdornment && {sx: {...ASYNC_ROW_STYLE, ...OPTION_STYLE}}}
                // MULTI_ASYNC_OBJECT
                {...multiple && endAdornment && {
                    checkBoxProps: {checked: checkBox.selected, sx: {...DROPDOWN_CELL_STYLE, borderRadius: 0}},
                    sx: {...ASYNC_ROW_STYLE, ...OPTION_STYLE},
                }}
                // MULTI_SYNC_STRING|OBJECT
                {...multiple && !endAdornment && {
                    checkBoxProps: {checked: checkBox.selected},
                    sx: selectAllLabel===option?SELECT_ALL_STYLE:undefined,
                }}
            />
        );
    };

    /**
     * renderInput
     * @param {TextFieldProps} params
     * @return {ReactElement}
     */
    const renderInput=(params:TextFieldProps):ReactElement => {
        let inputHelperText=" ";
        let label=" ";
        if (errors[props.field.key]) inputHelperText=errors[props.field.key]?.message as string;
        else if (isThreshold) inputHelperText=OPTIONS_SIZE.message;
        else if (helperText) inputHelperText=helperText;

        if (selectors) label = `Search ${selector?.label}`;
        else label = props.field.label;

        /**
         * onEndAdornmentClick
         * @return {void}
         */
        const onEndAdornmentClick=(args:React.MouseEvent|React.KeyboardEvent):void => {
            if (disabled) return;
            const validator = selector?.yup;
            if (validator) {
                validator(inputValue)
                    .then((result:boolean|yup.ValidationError) => {
                        if (result===true) {
                            setOpen(true);
                            setInputError({text: "", status: false});
                            endAdornment?.onClick(args, inputValue, selector);
                        } else {
                            setInputError({text: ((result as yup.ValidationError)?.errors[0]), status: true});
                        }
                    }).catch((_e) => console.log(_e));
            } else throw new Error("@@ERROR: EndAdornment No Validator Created");
        };

        /**
         * onClearClick
         * @param {React.MouseEvent} args
         * @return {void}
         */
        const onClearClick=(args:React.MouseEvent):void => {
            /**
            * asyncCallback
            * @return {void}
            */
            const asyncCallback=():void => {
                setInputValue("");
                setOpen(false);
                (props.field.autocompleteOptions as AutocompleteOptions).options=[]; // eslint-disable-line no-param-reassign
            };

            /**
            * syncCallback
            * @return {void}
            */
            const syncCallback=():void => {
                setValue(props.field.key, undefined);
            };

            const to=endAdornment?asyncCallback:syncCallback;
            const contextState={...lo.cloneDeep(context.state), dialog: dialog?.type||"NONE", to};
            if (dialog?.type && resolveWatchKey(formWatch, dialog?.watch)) context.setState(contextState);
            else to();
        };

        /**
         * onEndAdornmentKeyDown
         * @param {React.KeyboardEvent} args
         * @return {Promise<void>}
         */
        const onEndAdornmentKeyDown=async (args:React.KeyboardEvent):Promise<void> => {
            if (args.code==="Enter" || args.code==="NumpadEnter") {
                args.preventDefault(); // prevents submission
                args.stopPropagation(); // prevents freeSolo from adding plain text to the value array, since our async search expects an object structure.
                onEndAdornmentClick(args); // triggers search
            }
        };

        /**
         * onKeyDown
         * Rules: Trigger dialog when using Backspace or Delete
         * - on valid selected value: 1 Char and Select All
         * - escape non valid values
         * - ctrl+c and ctrl+x are disabled
         * @param {React.KeyboardEvent} args
         * @return {void}
         */
        const onKeyDown=(args:React.KeyboardEvent):void => {
            const {value}:any=args.target;
            const selection=(window as any)?.getSelection()?.toString();
            const formValue=formWatch[props.field.key];
            // escape COPY or CUT cmds
            if ((args.ctrlKey || args.metaKey) && ["c", "x"].includes(args.key)) {
                args.preventDefault();
                args.stopPropagation();
                return;
            }
            // listen on
            if (["Backspace", "Delete"].includes(args.key)) {
                if (
                    dialog?.type // lookup dialog prop
                    && resolveWatchKey(formWatch, dialog?.watch) // lookup watch prop
                    && !endAdornment // escape ASYNC variants
                    && (
                        (formValue!==undefined && value!==undefined && value.length===1)
                        || (selection && formValue!==undefined && (value===selection || value.length===selection.length))
                    )
                ) {
                    context.setState({...lo.cloneDeep(context.state), dialog: dialog?.type||"NONE", to: () => { setValue(props.field.key, undefined); }});
                }
                // prevents 'Backspace' from deleting the selection
                args.stopPropagation();
            }
        };

        /**
         * onChange
         * @param {React.ChangeEvent<HTMLInputElement>} args
         * @return {void}
         */
        const onChange=(args:React.ChangeEvent<HTMLInputElement>):void => {
            setInputValue(args.target.value);
        };

        const EraseIconButton=(<IconButton disabled={disabled} edge="end" onClick={onClearClick} size="small"><Close /></IconButton>);

        return (
            <ClickAwayListener onClickAway={():void => { if (!multiple) setOpen(false); }}>
                <Box>
                    <TextField
                        color="secondary"
                        {...params}
                        onKeyDown={onKeyDown}
                        onChange={endAdornment?onChange:params.onChange}
                        inputProps={{...params.inputProps, value: endAdornment?inputValue:params?.inputProps?.value}}
                        InputProps={{
                            ...params.InputProps,
                            ...endAdornment!==undefined && {onKeyDown: onEndAdornmentKeyDown},
                            endAdornment: (
                                <InputAdornment sx={{position: "absolute", right: "8px"}} position="end">
                                    {endAdornment
                                        ? (
                                            // ASYNC
                                            <Box display="flex" alignItems="center" gap="12px">
                                                {/* Loading spinner */}
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {/* Clear Button (if there is an input value or options are available) */}
                                                {(inputValue||options.length>0)&&(
                                                    <>
                                                        {EraseIconButton}
                                                        <Divider orientation="vertical" flexItem />
                                                    </>
                                                )}
                                                {/* End Adornment Icon */}
                                                <IconButton disabled={disabled} edge="end" onClick={onEndAdornmentClick} size="small">{endAdornment.icon}</IconButton>
                                            </Box>
                                        )
                                        : (
                                            // SYNC
                                            <>
                                                { params?.inputProps?.value&& options.includes(params?.inputProps?.value) && EraseIconButton}
                                                {/* dropdown open and close with the correct icon (default) */}
                                                <IconButton disabled={disabled} edge="end" onClick={() => setOpen(!open)} size="small">{open ? <ArrowDropUp /> : <ArrowDropDown />}</IconButton>
                                            </>

                                        )}
                                </InputAdornment>
                            ),
                        }}
                        helperText={inputError.text || inputHelperText}
                        error={inputError.status || errors[props.field.key]?.message!==undefined || isThreshold}
                        label={label}
                    />
                </Box>
            </ClickAwayListener>
        );
    };

    /**
     * getOptionLabel
     * @param {any} option
     * @return {string}
     */
    const getOptionLabel=(option:any):string => {
        if (typeof option==="string") return option;
        let optionLabel = "";
        Object.keys(option).forEach((key, i, arr) => {
            if (key===ITEM_META) return;
            optionLabel += `${option[key]}`;
            if (arr[i + 1]) optionLabel += OPTION_SEPARATOR;
        });
        return optionLabel;
    };

    /**
     * onSelectorChange
     * @param {Selector} option
     * @return {void}
     */
    const onSelectorChange=(option:Selector):void => {
        setInputValue("");
        (props.field.autocompleteOptions as AutocompleteOptions).options=[]; // eslint-disable-line no-param-reassign
        setSelector(option);
        setInputError({text: "", status: false});
    };

    /**
     * resolveSyncSelection
     * This resolves selection when "options" are pre-defined.
     * @param {any} data
     * @return {string[]}
     */
    const resolveSyncSelection=(data:any):string[] => {
        // If async variant, escape look in the useEffect and renderGroup
        if (multiple && endAdornment) return data;

        // selectAllLabel included
        if (Array.isArray(data) && data.includes(selectAllLabel)) {
            // Return all the options. Ie; everything "selected".
            if (!checkAll) {
                setCheckAll(true);
                return options;
            }
            // If the data includes "Select All", but the total selection
            // is less then ALL, splice "Select All" and return the remaining selection.
            if (options?.length && data.length < options.length) {
                const foundIdx = data.findIndex((el:string) => el === selectAllLabel);
                data.splice(foundIdx, 1);
                setCheckAll(false);
                return data;
            }
        }
        // If !checkAll, and the length of selections equals total options,
        if (!checkAll && options && selectAllLabel && (options.length - 1 === data.length)) {
            setCheckAll(true);
            return options;
        }
        // If the data does not include "Select All", but "checkAll" is true:
        if (checkAll) { setCheckAll(false); return []; }
        setCheckAll(false);
        return data;
    };

    /**
     * resolveOptions
     * @return {string[]}
     */
    const resolveOptions=():string[] => {
        if (selector) options.sort((a:any, b:any) => a[selector.key].toString().localeCompare(b[selector.key].toString(), undefined, {numeric: true}));
        if (selectAllLabel && options.length && !options.includes(selectAllLabel)) {
            options.unshift(selectAllLabel);
            return options;
        }
        return isThreshold?[]:options;
    };

    /**
     * onAutocompleteChange
     * @param {COnChange} onControllerChange
     * @return {void}
     */
    const onAutocompleteChange=(onControllerChange:COnChange) => (_:React.SyntheticEvent, data:any, reason:string):void => {
        if (["selectOption", "removeOption"].includes(reason) && props.onSelection) {
            props.onSelection(data);
            onControllerChange(data);
        } else {
            onControllerChange(resolveSyncSelection(data));
        }
    };

    /**
     * renderTags
     * @param {string[]} tagValue
     * @param {any} getTagProps
     * @return {ReactNode}
     */
    const renderTags=(tagValue:string[], getTagProps:any):ReactNode => {
        // MULTI_ASYNC_OBJECT
        // if (multiple && endAdornment) return <Typography>{`+${tagValue.length}`}</Typography>;
        // MULTI_SYNC_STRING
        if (multiple && !endAdornment && typeof tagValue[0]==="string") {
            return (
                <>
                    <Chip {...getTagProps({index: 0})} key={0} label={tagValue[0]} />
                    {(tagValue.length>1 && tagValue[0]!==selectAllLabel)&&<Typography>{`+${tagValue.length - 1}`}</Typography>}
                </>
            );
        }
        return null;
    };

    /**
     * onOpen
     * @return {void}
     */
    const onOpen=():void => {
        if (!props.onSelection) setOpen(true);
    };

    return (
        <Controller
            name={props.field.key}
            defaultValue={multiple ? [] : null}
            control={control}
            render={
                ({field: {onChange, value}}) => (
                    <FormGroup
                        className={selectors && "selector"}
                        sx={{
                            flexWrap: "nowrap",
                            "&.selector .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                borderTopLeftRadius: "0",
                                borderBottomLeftRadius: "0",
                            },
                        }}
                        row
                    >
                        {selectors && <SelectorButton disabled={disabled} onChange={onSelectorChange} options={selectors} />}
                        <AutocompleteBase
                            slotProps={endAdornment&&{paper: {sx: {minWidth: "400px"}}}} // minimum dropdown width for async search
                            disableCloseOnSelect={multiple}
                            onChange={onAutocompleteChange(onChange)}
                            value={value}
                            onOpen={onOpen}
                            onClose={() => { if (!endAdornment) setOpen(false); }}
                            open={isThreshold?false:open}
                            options={resolveOptions()}
                            disabled={disabled||false}
                            readOnly={readOnly||false}
                            renderInput={renderInput}
                            onBlur={() => { if (endAdornment) setOpen(false); }}
                            onFocus={() => { if (endAdornment && options.length) setOpen(true); }}
                            loading={loading}
                            getOptionLabel={getOptionLabel}
                            renderOption={renderOption}
                            freeSolo={(endAdornment!==undefined && options.length>0)} // handle warning (hook.js:608 MUI: The value provided to Autocomplete is invalid.) for async search
                            isOptionEqualToValue={(option:string, v:string) => (lo.isEqual(option, v))}
                            groupBy={() => ""} // overridden by renderGroup
                            renderGroup={(params:AutocompleteRenderGroupParams) => renderGroup(params, value, onChange)}
                            multiple={multiple}
                            limitTags={1}
                            renderTags={renderTags}
                            noOptionsText={endAdornment&&`No results found. Please check the ${selector?.label} and try again.`}
                            sx={{
                                width: "100%",
                                ".MuiAutocomplete-inputRoot": {borderRadius: DROPDOWN_BORDER_RADIUS},
                                ".MuiChip-root": {
                                    backgroundColor: theme.palette.primary.main,
                                    color: "white",
                                    fontSize: "13px",
                                },
                                ".MuiChip-root svg": {
                                    color: "white",
                                    opacity: "0.5",
                                    height: "18px",
                                },
                                "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.palette.primary.main,
                                },
                            }}
                        />
                    </FormGroup>
                )
            }
        />
    );
}

export {
    Button,
    ControlledAutocomplete,
};
