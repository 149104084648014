import React from "react";
import {FieldErrors, FieldValues, UseFormReturn} from "react-hook-form";
import {Box, ButtonProps, Grid} from "@mui/material";
import * as yup from "yup";
import {Search as SearchIcon} from "@mui/icons-material";
import {Button, Field, ControlledAutocomplete} from "../generics/inputs";
import {REPORTING_LEVEL, TIMESCALE, WHITE, YEAR_TYPE} from "../../config";

/**
 * validatePropertyName
 * @param {any} propertyName
 * @return {Promise<yup.ValidationError|boolean>}
 */
const validatePropertyName=async (propertyName:any):Promise<yup.ValidationError|boolean> => (
    yup
        .string()
        .matches(
            // letters, numbers, spaces, special characters(comma, (, ), ., :, #, @, &, -, >, ~, +) of length 1 to 100
            /^[A-Za-z0-9\s,():#.@&\-~+]{1,100}$/,
            "Please provide a valid property name: letters, numbers, and special characters are allowed.",
        )
        .validate(propertyName)
        .then((value:any) => true)
        .catch((err:any) => err));

/**
 * validateBbl
 * @param {any} bbl
 * @return {Promise<yup.ValidationError|boolean>}
 */
const validateBbl=async (bbl:any):Promise<yup.ValidationError|boolean> => (
    yup
        .string()
        .matches(
            // numbers of length 10
            /^\d{10}$/,
            "Please provide a valid 10-digit BBL. Special characters such as '-' are not allowed.",
        )
        .validate(bbl)
        .then((value:any) => true)
        .catch((err:any) => err));

/**
 * validateBin
 * @param {any} bin
 * @return {Promise<yup.ValidationError|boolean>}
 */
const validateBin=async (bin:any):Promise<yup.ValidationError|boolean> => (
    yup
        .string()
        .matches(
            // numbers of length 6 or 7
            /^\d{6,7}$/,
            "Please provide a valid 6 or 7-digit BIN.",
        )
        .validate(bin)
        .then((value:any) => true)
        .catch((err:any) => err));

/**
 * validateId
 * "id" traits [positive, integer]
 * @param {any} id
 * @return {Promise<boolean>}
 */
const validateId=async (id:any):Promise<boolean> => {
    const ERROR_MESSAGE="Please provide a valid BDBID: only numbers are allowed, with a length of 1 to 5 digits.";
    return (
        yup
            .number()
            .integer()
            .typeError(ERROR_MESSAGE)
            .min(1, ERROR_MESSAGE) // Ensure 1 digit min
            .max(99999, ERROR_MESSAGE) // Ensure 5 digits max
            .validate(id)
            .then(() => true)
            .catch((err:any) => err));
};

/**
 * validateAddress
 * "address"
 * @param {any} address
 * @return {Promise<boolean>}
 */
const validateAddress=async (address:any):Promise<boolean> => (
    yup
        .string()
        .matches(
            // letters, numbers, spaces, special characters(comma, @, &, (, ), -, .) of length 1 to 100
            /^[A-Za-z0-9\s,@&().-]{1,100}$/,
            "Please provide a valid address: letters, numbers, and special characters are allowed.",
        )
        .validate(address)
        .then((value:any) => true)
        .catch((err:any) => err));

/**
 * validateAccount
 * @param {any} account
 * @return {Promise<yup.ValidationError|boolean>}
 */
const validateAccount=async (account:any):Promise<yup.ValidationError|boolean> => (
    yup
        .string()
        .test(
            "size-test",
            "",
            (value:any, testContext:yup.TestContext):yup.ValidationError|boolean => {
                if (value.length<2 || value.length>44) return testContext.createError({message: "Please provide a valid account between 2 and 44 characters: letters, numbers, and special characters are allowed."});
                return true;
            },
        )
        .validate(account)
        .then((value:any) => true)
        .catch((err:any) => err));

/**
 * validateAccountMeter
 * @param {any} meter
 * @return {Promise<yup.ValidationError|boolean>}
 */
const validateAccountMeter=async (accountMeter:any):Promise<yup.ValidationError|boolean> => (
    yup
        .string()
        .test(
            "size-test",
            "",
            (value:any, testContext:yup.TestContext):yup.ValidationError|boolean => {
                if (value.length<3) return testContext.createError({message: "Please provide a valid Account-Meter. Account-Meter format should be ACC1234-MTR5678."});
                return true;
            },
        )
        .validate(accountMeter)
        .then((value:any) => true)
        .catch((err:any) => err));

/**
 * validateOECID
 * @param {any} oecid
 * @return {Promise<yup.ValidationError|boolean>}
 */
const validateOECID=async (oecid:any):Promise<yup.ValidationError|boolean> => (
    // Note: string is used to preserve the leading zeros in OECID(e.g. "012345")
    yup
        .string()
        // validate if it's a number and 6 or 7 digits
        .matches(/^\d{6,7}$/, "Please provide a valid 6 or 7-digit OECID.")
        .validate(oecid)
        .then((value:any) => true)
        .catch((err:any) => err));

const SELECTOR_WIDTH=50;

export const Fields:Field[] = [
    {
        key: "reporting_level",
        label: "Reporting Level",
        yup: yup.string().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: REPORTING_LEVEL.map((level) => level.label),
            readOnly: false,
            disabled: false,
            dialog: {type: "LEVEL_CHANGE_PROMPT_DIALOG", watch: ["property", "meter", "agency"]},
        },
    },
    {
        key: "meter",
        label: "",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            multiple: true,
            readOnly: false,
            disabled: false,
            helperText: "Hit 'Enter' to start searching",
            selectors: [
                {key: "account_number", label: "Account", width: SELECTOR_WIDTH, yup: validateAccount},
                {key: "account_meter", label: "Account-Meter", width: SELECTOR_WIDTH, yup: validateAccountMeter},
                {key: "assoc_facility_street_address", label: "Assoc. Address", width: SELECTOR_WIDTH, yup: validateAddress, tooltip: "Associated BDBID Address"},
                {key: "assoc_facility_bdbid", label: "Assoc. BDBID", width: SELECTOR_WIDTH, yup: validateId, tooltip: "Associated BDBID"},
                {key: "assoc_facility_oecid", label: "OECID", width: SELECTOR_WIDTH, yup: validateOECID},
            ],
            endAdornment: {
                icon: <SearchIcon />,
                onClick: async (args:React.MouseEvent|React.KeyboardEvent) => {},
            },
            optionsHeader: [
                {key: "dem_meter_code", label: "Meter", width: 25},
                {key: "meter_type", label: "Meter Type", width: 25},

            ],
        },
    },
    {
        key: "property",
        label: "",
        yup: yup.mixed().nullable(),
        type: "autocomplete",
        autocompleteOptions: {
            options: [],
            multiple: true,
            readOnly: false,
            disabled: false,
            helperText: "Hit 'Enter' to start searching",
            selectors: [
                {key: "address", label: "Address", width: SELECTOR_WIDTH, yup: validateAddress},
                {key: "bbl", label: "BBL", width: SELECTOR_WIDTH, yup: validateBbl, tooltip: "Borough Block Lot"},
                {key: "bdbid", label: "BDBID", width: SELECTOR_WIDTH, yup: validateId},
                {key: "bin", label: "BIN", width: SELECTOR_WIDTH, yup: validateBin, tooltip: "Building Identification Number"},
                {key: "property_name", width: SELECTOR_WIDTH, label: "Property Name", yup: validatePropertyName, tooltip: "Property Name"},
            ],
            endAdornment: {
                icon: <SearchIcon />,
                onClick: async (args:React.MouseEvent|React.KeyboardEvent) => {},
            },
            optionsHeader: [
                {key: "property_name", label: "Property Name", width: 50, altOptionHeader: {key: "address", label: "Address", width: 50}},
            ],
        },
    },
    {
        key: "agency",
        label: "Agency",
        yup: yup.array().min(1, "Select an agency").required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            multiple: true,
            selectAllLabel: "All Agencies",
            options: [],
            readOnly: false,
            disabled: false,
        },
    },
    {
        key: "timescale",
        label: "Timescale",
        yup: yup.string().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: TIMESCALE.map((timescale) => timescale.label),
            readOnly: false,
            disabled: false,
        },
    },
    {
        key: "year_type",
        label: "Year Type",
        yup: yup.string().required("Required"),
        type: "autocomplete",
        autocompleteOptions: {
            options: YEAR_TYPE.map((type) => type.label),
            readOnly: false,
            disabled: false,
        },
    },
];

interface Props{
    onSubmit:{callback: (values:FieldValues) => void, disabled: boolean}
    onError:(error:FieldErrors) => void,
    onSelection:(data:any) => void
    formReturn:UseFormReturn<FieldValues>
    fields:Field[],
    dataGrid:React.ReactElement|null
    disabled:boolean
    loading?:boolean
}

/**
 * BasicExport
 * @param {Props} props
 * @return {React.ReactElement}
 */
function BasicExport(props:Props):React.ReactElement {
    /**
     * findField
     * @params {string} key
     * @return {Field}
     */
    const findField=(key:string):any => (
        props.fields.find((x) => x.key === key)
    );

    /**
     * renderField
     * @param {string} field
     * @param {UseFormReturn<FieldValues>} formReturn
     * @param {number} width
     * @param {any} onSelection
     * @return {React.ReactElement}
     */
    const renderAutocomplete=(field:string, formReturn:UseFormReturn<FieldValues>, width:number, onSelection?:any):React.ReactElement => (
        <Grid key={findField(field).key} item xs={12} lg={width || 3}>
            <ControlledAutocomplete
                formReturn={formReturn}
                onSelection={onSelection}
                field={findField(field)}
                loading={props.loading}
            />
        </Grid>
    );

    const sharedButtonStyles: ButtonProps={
        sx: {width: "auto", textTransform: "capitalize", borderRadius: "8px", "&.Mui-disabled": {color: WHITE, backgroundColor: "#0000008C"}},
        size: "large",
        variant: "contained",
    };
    return (
        <form onSubmit={props.formReturn.handleSubmit(props.onSubmit.callback, props.onError)}>
            <Box sx={{backgroundColor: WHITE, borderRadius: "20px", padding: "80px 0 24px", paddingX: {xs: "16px", sm: "48px"}}}>
                <Grid container spacing={3} marginBottom="80px">
                    { props.fields.map((x) => (
                        (x.key === "reporting_level" && renderAutocomplete("reporting_level", props.formReturn, 2, undefined))
                        || (x.key === "meter" && renderAutocomplete("meter", props.formReturn, 6, props.onSelection))
                        || (x.key === "property" && renderAutocomplete("property", props.formReturn, 6, props.onSelection))
                        || (x.key === "agency" && renderAutocomplete("agency", props.formReturn, 6, undefined))
                        || (x.key === "timescale" && renderAutocomplete("timescale", props.formReturn, 2, undefined))
                        || (x.key === "year_type" && renderAutocomplete("year_type", props.formReturn, 2, undefined))
                    ))}
                </Grid>
                <Box sx={props.dataGrid && {marginTop: "96px", marginBottom: "80px"}}>{ props.dataGrid }</Box>
                <Box sx={{display: "flex", paddingTop: "24px", justifyContent: "center"}}>
                    <Button
                        {...sharedButtonStyles}
                        label="Download"
                        disabled={props.onSubmit.disabled || props.disabled}
                        type="submit"
                    />
                </Box>
            </Box>
        </form>
    );
}

export {validatePropertyName, validateBbl, validateBin, validateId, validateAddress, validateAccount, validateAccountMeter, validateOECID};
export default BasicExport;
