import React, {SetStateAction, useState, Dispatch, useEffect} from "react";
import {Box, Theme, Typography} from "@mui/material";
import {GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import {useNavigate} from "react-router-dom";
import moment from "moment";
// components
import {FieldErrors, FieldValues, useForm, UseFormReturn} from "react-hook-form";
import {AxiosError, AxiosResponse} from "axios";
import {yupResolver} from "@hookform/resolvers/yup";
import {enqueueSnackbar} from "notistack";
import * as yup from "yup";
import _ from "lodash";
import DataGrid from "../components/generics/DataGrid";
import {BasicExportForm} from "../components/forms";
import {Fields as DataExportFormFields} from "../components/forms/BasicExport";
import {Field, Selector} from "../components/generics/inputs";
import useCall from "../components/generics/useCall";
import {BASE_REPORTING_ROUTE, REPORTING_LEVEL_ROUTE, REPORTING_LEVEL as REPORTING_LEVEL_KEYS, TIMESCALE, YEAR_TYPE} from "../config";
import {alterFieldsViewMode, constructBasicDataPostConfig, formatBasicDataExportPayload, resolveOptionKeyLabel} from "../handlers";
import Alert from "../components/generics/Alert";
// import Backdrop from "../components/generics/Backdrop";
import BasicDataExportIntro from "../components/layout/BasicDataExportIntro";
import Dialog, {Props as DialogProps} from "../components/generics/Dialog";
import {useContext} from "../components/generics/Context";
import {DialogType} from "../types";

const PROPERTY_COLUMNS:GridColDef[] = [
    {field: "property_name",
        headerName: "Property Name",
        flex: 2,
        minWidth: 250,
        renderCell: (params:GridRenderCellParams) => <Box sx={{fontWeight: "bold", color: (theme:Theme) => theme.palette.primary.main}}>{params.value}</Box>,
    },
    {field: "bdbid", headerName: "BDBID", flex: 0.5, minWidth: 60},
    // {field: "oecid", headerName: "OECID", flex: 1}, Note: Not on the API.
    {field: "organization_name", headerName: "Agency", flex: 1, minWidth: 100},
    {field: "recordtype", headerName: "Type", flex: 1, minWidth: 125},
    {field: "property_status", headerName: "Status", flex: 1, minWidth: 125},
    {field: "borough", headerName: "Borough", flex: 1, minWidth: 100},
    {field: "address", headerName: "Street Address", flex: 1.5, minWidth: 225},
];

const METER_COLUMNS:GridColDef[] = [
    {field: "dem_meter_code",
        headerName: "Meter Code",
        flex: 1,
        minWidth: 125,
        renderCell: (params:GridRenderCellParams) => <Box sx={{fontWeight: "bold", color: (theme:Theme) => theme.palette.primary.main}}>{params.value}</Box>,
    },
    {field: "account_number", headerName: "Account Number", flex: 1, minWidth: 200},
    {field: "beis_meter_id", headerName: "BEIS Meter ID", flex: 1, minWidth: 125},
    {field: "meter_type", headerName: "Meter Type", flex: 1.5, minWidth: 200},
    {field: "assoc_facility_bdbid", headerName: "Facility bdbid", flex: 1, minWidth: 110},
    {field: "assoc_facility_oecid", headerName: "Facility OECID", flex: 1, minWidth: 110},
    {field: "assoc_facility_street_address", headerName: "Facility address", flex: 1.5, minWidth: 200},
];

interface State{
    bucket:any[]
    reportDownloadResponse:AxiosResponse|AxiosError|undefined
    reportDownloadTimeoutId:NodeJS.Timeout|undefined
    isSubmitDisabled:boolean
}

/**
 * BasicDataExport
 * @return {React.ReactElement}
 */
function BasicDataExport():React.ReactElement {
    const navigate=useNavigate();
    const context=useContext();
    const [state, setState]:[State, Dispatch<SetStateAction<State>>]=useState<State>({
        bucket: [],
        reportDownloadResponse: undefined,
        reportDownloadTimeoutId: undefined,
        isSubmitDisabled: true,
    });
    const [formFields, setFormFields]=useState<Field[]>(DataExportFormFields.filter((x) => ["reporting_level", "timescale"].includes(x.key)));
    const {get, loading}=useCall();
    const {call: callDownload, loading: loadingDownload}=useCall(); // needed to use a separate hook for the download call to avoid sharing the loading state

    const formReturn:UseFormReturn=useForm({mode: "onSubmit", resolver: yupResolver(yup.object(formFields.reduce((a:any, v:any) => ({...a, [v.key]: v.yup}), {})))});
    const activeReportingLevel = resolveOptionKeyLabel(formReturn.getValues().reporting_level, REPORTING_LEVEL_KEYS)||"";

    /**
   * resolveFieldOptions
   * @param {AxiosResponse} data
   * @param {Field} field
   * @return {void}
   */
    const resolveFieldOptions=(data:AxiosResponse, field:Field):void => {
        if (field?.autocompleteOptions?.optionsHeader && field?.autocompleteOptions?.selectors) {
            const keys = field.autocompleteOptions.optionsHeader.map((header:any) => header.key);
            const selectors = field.autocompleteOptions.selectors.map((s:Selector) => s.key);
            field.autocompleteOptions.options=formatBasicDataExportPayload(data, [...selectors, ...keys]); // eslint-disable-line no-param-reassign
            setFormFields((prevFields:Field[]) => {
                const clonedPrevFields = _.cloneDeep(prevFields);
                clonedPrevFields[prevFields.findIndex((f:Field) => f.key===field.key)]=field;
                return clonedPrevFields;
            });
        }
    };

    /**
   * onMeterSearch
   * @param {React.MouseEvent} args
   * @param {any} value
   * @return {Promise<void>}
   */
    const onMeterSearch=(field:Field) => async (args:React.MouseEvent, value:any, selector:Selector):Promise<void> => {
        const METER_QUERY = selector.key === "assoc_facility_bdbid" ? `?assoc_facility_bdbid=${value}` : `?${selector.key}__contains=${value}`;
        const response:AxiosResponse|AxiosError=await get(`${REPORTING_LEVEL_ROUTE.replace(/:reportingLevel/g, "meter")}${METER_QUERY}`);
        if (!(response instanceof AxiosError)) resolveFieldOptions(response, field);
        else enqueueSnackbar("Error Meter Fetch", {variant: "error"});
    };

    /**
     * onPropertySearch
     * @param {React.MouseEvent} args
     * @param {any} value
     * @param {Selector} selector
     * @return {Promise<void>}
     */
    const onPropertySearch=(field:Field) => async (args:React.MouseEvent, value:any, selector:Selector):Promise<void> => {
        const PROPERTY_QUERY = selector.key === "bdbid" ? `?bdbids=${value}` : `?${selector.key}__contains=${value}`;
        const response:AxiosResponse|AxiosError=await get(`${REPORTING_LEVEL_ROUTE.replace(/:reportingLevel/g, "property")}${PROPERTY_QUERY}`);
        if (!(response instanceof AxiosError)) resolveFieldOptions(response, field);
        else enqueueSnackbar("Error Property Fetch", {variant: "error"});
    };

    /**
     * fetchAgencies
     * @param {Field} field
     * @return {Promise<void>}
     */
    const fetchAgencies=async (field:Field):Promise<void> => {
        if (field.autocompleteOptions?.options.length!==0) return;
        const response:AxiosResponse|AxiosError=await get(`${REPORTING_LEVEL_ROUTE.replace(/:reportingLevel/g, "agency")}`);
        if (!(response instanceof AxiosError)) {
            field.autocompleteOptions.options=response.data.results.map((i:any) => i.organization_name).sort(); // eslint-disable-line no-param-reassign
            setFormFields((prevFields:Field[]) => {
                const clonedPrevFields = _.cloneDeep(prevFields);
                clonedPrevFields[prevFields.findIndex((f:Field) => f.key===field.key)]=field;
                return clonedPrevFields;
            });
        } else enqueueSnackbar("Error Agency Fetch", {variant: "error"});
    };

    const REPORTING_LEVEL:any={
        meter: {
            title: "Meters",
            columns: METER_COLUMNS,
            onSearch: onMeterSearch,
            uniqueId: "account_meter",
            keys: METER_COLUMNS.map((k) => k.field),
        },
        property: {
            title: "Properties",
            columns: PROPERTY_COLUMNS,
            onSearch: onPropertySearch,
            uniqueId: "bdbid",
            keys: PROPERTY_COLUMNS.map((k) => k.field),
        },
        agency: {
            title: "Agency",
            columns: undefined,
            onSearch: undefined,
            uniqueId: undefined,
            keys: undefined,
        },
    };

    /**
     * removeFields
     * @param {string[]} keys
     * @return {void}
     */
    const removeFields=(keys:string[]):void => {
        setFormFields((prevFields:Field[]) => prevFields.filter((f:Field) => !keys.includes(f.key)));
    };

    /**
     * resolveSubmitStatus
     * @param {State} prevState
     * @param {FieldValues} values
     * @param {any[]} bucket
     * @return {void}
     */
    const resolveSubmitStatus=(prevState:State, values:FieldValues, bucket:any[]):State => {
        const REPORTING_LEVEL_VALUE = resolveOptionKeyLabel(values.reporting_level, REPORTING_LEVEL_KEYS) as string;
        const TIMESCALE_VALUE = resolveOptionKeyLabel(values.timescale, TIMESCALE);
        const isPropertyOrMeter = ["property", "meter"].includes(REPORTING_LEVEL_VALUE);
        const hasValidBucket = bucket.length > 0;
        const isAgency = REPORTING_LEVEL_VALUE === "agency";
        const hasAgencyValues = values.agency?.length > 0;
        if ((isPropertyOrMeter && hasValidBucket) || (isAgency && hasAgencyValues)) {
            if ((TIMESCALE_VALUE === "monthly") || (TIMESCALE_VALUE === "annual" && values.year_type)
            ) return ({...prevState, isSubmitDisabled: false, bucket});
            return ({...prevState, isSubmitDisabled: true, bucket});
        }
        return ({...prevState, isSubmitDisabled: true, bucket: []});
    };

    /**
     * resolveFormFields
     * @param {string} name
     * @param {number} index
     * @return {void}
     */
    const resolveFormFields=(name:string, index?:number):void => {
        if (!name) {
            removeFields(Object.keys(REPORTING_LEVEL));
            return;
        }

        // Find the selected input from Field[]
        const field:Field=DataExportFormFields.find((f:Field) => f.key===name) as Field;

        // overrirde EndAdornment when available OR GET agencies
        if (field.autocompleteOptions?.endAdornment) field.autocompleteOptions.endAdornment.onClick=REPORTING_LEVEL[field.key].onSearch(field);
        else if (field.key==="agency") fetchAgencies(field);

        // If the input already exists, exit to avoid duplicates.
        if (formFields.find((f:Field) => f.key===field.key)) return;

        // Merge the inputs, and sort based on Field[].
        setFormFields((prevFields:Field[]) => {
            let clone=_.cloneDeep(prevFields);
            // House cleaning
            if (Object.keys(REPORTING_LEVEL).includes(name)) clone=clone.filter((f:Field) => !Object.keys(REPORTING_LEVEL).filter((i:string) => i!==name).includes(f.key));
            clone.splice(index||clone.length, 0, field);
            return clone;
        });
    };

    useEffect(() => {
        const subscription = formReturn.watch(_.debounce((values:any, {name, type}:any) => {
            if (name === "reporting_level") {
                setState((prevState:State) => resolveSubmitStatus(prevState, values, []));
                resolveFormFields(resolveOptionKeyLabel(values.reporting_level, REPORTING_LEVEL_KEYS) as string, 1);
                // reset form fields; update reporting level; persist timescale and year type
                formReturn.reset({reporting_level: values.reporting_level, timescale: values.timescale, year_type: values.year_type});
                // retrieve the field corresponding to the previously active reporting level
                const previousField:Field=DataExportFormFields.find((f:Field) => f.key===activeReportingLevel) as Field;
                // clear options to prevent persistence when switching reporting levels
                if (previousField?.autocompleteOptions?.options?.length) previousField.autocompleteOptions.options = [];
            } else if (name === "agency") {
                setState((prevState:State) => resolveSubmitStatus(prevState, values, []));
            } else if (name === "timescale") {
                if (resolveOptionKeyLabel(values.timescale, TIMESCALE)==="annual") resolveFormFields("year_type");
                else {
                    formReturn.reset(_.omit(values, ["year_type"]));
                    removeFields(["year_type"]);
                }
                setState((prevState:State) => resolveSubmitStatus(prevState, values, prevState.bucket));
            } else if (name === "year_type") {
                setState((prevState:State) => resolveSubmitStatus(prevState, values, prevState.bucket));
            }
        }, 0));
        return () => subscription.unsubscribe();
    });

    // un-register -- defaulting dialog to NONE
    useEffect(() => () => {
        if (context.state.dialog!=="NONE") context.setState({..._.cloneDeep(context.state), dialog: "NONE", to: undefined});
    }, [context]);

    /**
     * onClearAllDialogTrigger
     * @return {void}
     */
    const onClearAllDialogTrigger=():void => context.setState({..._.cloneDeep(context.state), dialog: "CLEAR_ALL_PROMPT_DIALOG", to: undefined});

    /**
     * onRowsDelete
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onRowsDelete=(args:React.MouseEvent):void => {
        formReturn.setValue(activeReportingLevel, []);
        setState((prevState:State) => resolveSubmitStatus(prevState, formReturn.getValues(), []));
        context.setState({..._.cloneDeep(context.state), dialog: "NONE", to: undefined});
    };

    /**
     * onLevelChangeContinue
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onLevelChangeContinue=(args:React.MouseEvent):void => {
        if (typeof context.state.to==="function") {
            context.state.to(args);
            context.setState({..._.cloneDeep(context.state), dialog: "NONE", to: undefined});
        }
    };

    /**
     * onPromptContinue
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onPromptContinue=(args: React.MouseEvent):void => {
        if (typeof context.state.to==="string") navigate(context.state.to, {state: {from: window.location.pathname}});
        else navigate("/");
    };

    /**
     * onRowDelete
     * @param {string|number} id
     * @return {void}
     */
    const onRowDelete=(row:any):void => {
        // 1. Retrieve the existing form values for the current reporting level
        const currentFieldValues = formReturn.getValues()[activeReportingLevel];

        // 2. Filter out the row we’re removing, based on the unique ID
        const updatedFieldValues = currentFieldValues.filter((item: any) => (
            item[REPORTING_LEVEL[activeReportingLevel].uniqueId] !== row[REPORTING_LEVEL[activeReportingLevel].uniqueId]
        ));

        // 3. Update the form values
        formReturn.setValue(activeReportingLevel, updatedFieldValues);

        // 4. Update the datagrid's rows in the bucket
        setState((prevState:State) => resolveSubmitStatus(prevState, formReturn.getValues(), state.bucket.filter((r:any) => r!==row)));
    };

    /**
     * onDialogCancel
     * @param {React.MouseEvent} args
     * @return {void}
     */
    const onDialogCancel=(args: React.MouseEvent):void => context.setState({..._.cloneDeep(context.state), dialog: "NONE", to: undefined});

    /**
     * onDownloadSuccess
     * @param {AxiosResponse} res
     * @param {FieldValues} values
     * @return {void}
     */
    const onDownloadSuccess=(res:AxiosResponse, values:FieldValues):void => {
        const url=window.URL.createObjectURL(new Blob([res.data], {type: "text/csv"}));
        const link=document.createElement("a");
        const yearType=`${resolveOptionKeyLabel(values.timescale, TIMESCALE)==="annual"?`_${resolveOptionKeyLabel(values.year_type, YEAR_TYPE)==="calendar"?"CY":"FY"}`:""}`;
        const fileName = `Epsilon_${values.reporting_level}_${values.timescale}${yearType}_${moment().format("YYYY-MM-DD__hh-mm")}.csv`;
        link.href=url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // re-enable form fields once download is complete
        setFormFields(alterFieldsViewMode({base: formFields}, "NONE", "disabled").base);
    };

    /**
     * onDownload
     * @param {FieldValues} values
     * @return {Promise<void>}
     */
    const onDownload=async (values:FieldValues):Promise<void> => {
        setFormFields(alterFieldsViewMode({base: formFields}, "DISABLED_MODE", "disabled").base); // disable form fields
        const response=await callDownload(constructBasicDataPostConfig(values, state.bucket.map((i:any) => i[REPORTING_LEVEL[REPORTING_LEVEL_KEYS.find((k:any) => k.label===values.reporting_level)?.key as string].uniqueId])));
        if (response instanceof AxiosError) setFormFields(alterFieldsViewMode({base: formFields}, "NONE", "disabled").base); // re-enable form fields
        else if (response) onDownloadSuccess(response, values);

        // clear previous timeout if it exists
        if (state.reportDownloadTimeoutId) clearTimeout(state.reportDownloadTimeoutId);

        // set a new timeout to clear response and timeout id after 6 seconds
        const timeoutId = setTimeout(() => {
            setState((prevState: State) => ({
                ...prevState,
                reportDownloadResponse: undefined,
                reportDownloadTimeoutId: undefined,
            }));
        }, 6000);

        // update state with the new response and timeout ID
        setState((prevState: State) => ({
            ...prevState,
            reportDownloadResponse: response,
            reportDownloadTimeoutId: timeoutId,
        }));
    };

    /**
     * onError
     * @param {FieldErrors} errors
     * @return {void}
     */
    const onError=(errors:FieldErrors):void => {
        enqueueSnackbar("Fill Required Fields", {variant: "info"});
    };

    /**
     * onItemsSelection
     * @param {any} items
     * @return {void}
     */
    const onItemsSelection=(items:any):void => {
        setState((prevState:State) => resolveSubmitStatus(prevState, formReturn.getValues(), items.map((item:any) => item._meta).reverse())); // eslint-disable-line no-underscore-dangle
    };

    /**
     * onItemSelection
     * @param {any} item
     * @return {void}
     */
    const onItemSelection=(item:any):void => {
        const {uniqueId} = REPORTING_LEVEL[activeReportingLevel];
        // if the item already exists in the bucket, show an alert and return
        if (state.bucket.find((bucketItem) => bucketItem[uniqueId] === item[uniqueId])) {
            enqueueSnackbar(`${item[uniqueId]} Item already exists!`, {variant: "info"});
            return;
        }
        setState((prevState:State) => resolveSubmitStatus(prevState, formReturn.getValues(), [item._meta, ...state.bucket])); // eslint-disable-line no-underscore-dangle
    };

    /**
     * onSelection
     * @param {any} selection
     * @return {void}
     */
    const onSelection=(selection:any):void => {
        if (!Array.isArray(selection)) {
            onItemSelection(selection);
            return;
        }
        onItemsSelection(selection);
    };

    const DIALOGS: Omit<DialogProps, "status">[] = [
        // prompt dialog
        {
            dialog: "PROMPT_DIALOG",
            title: "Warning: Data Loss on Exit",
            content: (
                <Box>
                    <Typography>
                        If you leave this page, you will be redirected and all the data you&apos;ve searched for will be lost.
                        <br />
                        <br />
                        <b>Note: </b>
                        If you want to download the data you&apos;ve searched for so far, please do so before exiting or switching between different reporting levels (e.g., from meters to property).
                        Switching will still result in the loss of all current data.
                    </Typography>
                </Box>
            ),
            actions: [
                {label: "Cancel", onClick: onDialogCancel},
                {label: "Continue", onClick: onPromptContinue},
            ],
        },
        // clear all dialog
        {
            dialog: "CLEAR_ALL_PROMPT_DIALOG",
            title: "Warning: Data Loss on Deletion",
            content: (
                <Box>
                    <Typography>
                        If you clear all of your selected meters or properties, all the data you&apos;ve searched for will be lost.
                        <br />
                        <br />
                        <b>Note: </b>
                        If you want to download the data you&apos;ve searched for so far, please do so before clearing your selected properties or meters.
                    </Typography>
                </Box>
            ),
            actions: [
                {label: "Cancel", onClick: onDialogCancel},
                {label: "Continue", onClick: onRowsDelete},
            ],
        },
        {
            dialog: "LEVEL_CHANGE_PROMPT_DIALOG",
            title: "Warning: Data Loss on Level Change",
            content: (
                <Box>
                    <Typography>
                        If you change your reporting level, all the data you&apos;ve searched for will be lost.
                        <br />
                        <br />
                        <b>Note: </b>
                        If you want to download the data you&apos;ve searched for so far, please do so before switching between different reporting levels (e.g., from meters to property).
                        Switching will still result in the loss of all current data.
                    </Typography>
                </Box>
            ),
            actions: [
                {label: "Cancel", onClick: onDialogCancel},
                {label: "Continue", onClick: onLevelChangeContinue},
            ],
        },
    ];
    return (
        <Box>
            {/* dialogs */}
            {DIALOGS.map((dialog: Omit<DialogProps, "status">) => (
                <Dialog
                    key={dialog.dialog}
                    status={context.state.dialog as DialogType}
                    {...dialog}
                />
            ))}
            {/* backdrop */}
            {/* <Backdrop open={state.reportDownloadResponse!==undefined && loading} /> */}
            {/* intro */}
            <Box sx={{marginBottom: "32px"}}><BasicDataExportIntro /></Box>
            {/* form */}
            <BasicExportForm
                formReturn={formReturn}
                onSelection={onSelection}
                loading={loading}
                onError={onError}
                onSubmit={{callback: onDownload, disabled: state.isSubmitDisabled}}
                fields={formFields}
                disabled={loadingDownload}
                dataGrid={Object.keys(REPORTING_LEVEL).filter((i:any) => i!=="agency").includes(activeReportingLevel) ? (
                    <DataGrid
                        title={REPORTING_LEVEL[activeReportingLevel].title}
                        column={REPORTING_LEVEL[activeReportingLevel].columns}
                        rows={state.bucket}
                        onRowsDelete={onClearAllDialogTrigger}
                        onRowDelete={onRowDelete}
                        disabled={loadingDownload}
                        getRowId={(row:GridRowModel) => row[REPORTING_LEVEL[activeReportingLevel].uniqueId]}
                    />
                ):null}
            />
            {/* Alerts */}
            <Alert title="Success" body="Report has been exported as a .csv file." severity="success" status={state.reportDownloadResponse?.status===200} sx={{box: {marginTop: "32px"}}} />
            <Alert
                title="500 Internal Server Error"
                body="Something went wrong."
                severity="error"
                status={state.reportDownloadResponse && "status" in state.reportDownloadResponse && state.reportDownloadResponse?.status!==200}
                sx={{box: {marginTop: "32px"}}}
            />
        </Box>
    );
}

export default BasicDataExport;
