import {AxiosError, AxiosResponse} from "axios";
import {User} from "@auth0/auth0-react";
import {enqueueSnackbar} from "notistack";
import api from "../../api";
import {env, SLACK_ROUTE} from "../../config";

/**
 * slackCall
 * @param {AxiosError} payload
 * @param {User} user
 * @return {Promise<void>}
 */
export const slackCall=async (payload:AxiosError, user:User):Promise<void> => {
    // AxiosError returns raw error and AxiosResponse.
    // both are reported seperately per following
    const {response, ...rest}=payload;
    const content={
        "@@Axios Raw Payload": rest,
        "@@Axios Raw Payload Response": response||"NONE!",
    };

    await api({
        method: "post",
        url: SLACK_ROUTE,
        data: {
            blocks: [
                {type: "header", text: {type: "plain_text", text: "Log", emoji: true}},
                {
                    type: "context",
                    elements: [
                        {type: "plain_text", text: `User Email: ${user.email}`, emoji: true},
                        {type: "plain_text", text: `Environment: ${env.REACT_APP_ENVIRONMENT}`, emoji: true},
                        {type: "plain_text", text: "@zxc", emoji: true},
                    ],
                },
                {type: "divider"},
                {
                    type: "rich_text",
                    elements: [
                        {
                            type: "rich_text_preformatted",
                            elements: [
                                {type: "text", text: `${JSON.stringify(content, null, 2)}`},
                            ],
                        },
                    ],
                },
            ],
        },
    })
        .then((res:AxiosResponse) => res)
        .catch((err:AxiosError) => enqueueSnackbar("Failed Reporting Error", {variant: "error"}));
};

export default slackCall;
