import {AxiosResponse, AxiosRequestConfig} from "axios";
import _ from "lodash";
import {FieldValues} from "react-hook-form";
import {ITEM_META} from "../components/generics/inputs";
import {REPORTING_DOWNLOAD, REPORTING_DOWNLOAD_PAYLOAD_CONFIG, REPORTING_LEVEL, TIMESCALE, YEAR_TYPE} from "../config";

/**
 * formatBasicDataExportPayload
 * @param {AxiosResponse} payload
 * @param {string[]} keys
 * @return {any}
 */
const formatBasicDataExportPayload=(payload:AxiosResponse, keys:string[]):any[] => {
    if (!payload?.data?.results) return [];
    const content:any = [];

    /**
     * isDuplicate
     * @param {any} item
     * @return {boolean}
     */
    const isDuplicate=(item:any):boolean => {
        for (let i=0; i<content.length; i+=1) if (_.isEqual(_.omit(content[i], ITEM_META), item)) return true;
        return false;
    };

    // Loop over the results, and filter out only the keys we want using keys argument
    payload.data.results.forEach((item:any) => {
        const filteredPayload = Object.keys(item)
            .filter((key) => keys.includes(key))
            .reduce((obj, key) => ({
                ...obj,
                [key]: item[key],
            }), {});
        if (!content.includes(filteredPayload) && !isDuplicate(filteredPayload)) content.push({[ITEM_META]: item, ...filteredPayload});
    });
    return content;
};

/**
 * constructBasicDataPostConfig
 * @param {FieldValues} values
 * @param {any} bucket
 * @return {AxiosRequestConfig}
 */
const constructBasicDataPostConfig=(values:FieldValues, bucket:any[]):AxiosRequestConfig => {
    const PAYLOAD_KEYS:any={agency: "organization_names", meter: "accountmeters", property: "bdbids"};
    const timescale=TIMESCALE.find((i:any) => i.label===values.timescale)?.key as string;
    const reportingLevel=REPORTING_LEVEL.find((i:any) => i.label===values.reporting_level)?.key as string;
    const yearType=YEAR_TYPE.find((i:any) => i.label===values.year_type)?.key as string;
    const data:any=timescale==="annual"?{...(REPORTING_DOWNLOAD_PAYLOAD_CONFIG[timescale][yearType])}:{...REPORTING_DOWNLOAD_PAYLOAD_CONFIG[timescale]};

    if (timescale==="annual") data.year_type=values.year_type;
    data[PAYLOAD_KEYS[reportingLevel]]=reportingLevel==="agency"?values.agency:bucket;

    return {
        method: "post",
        url: `${REPORTING_DOWNLOAD.replace(/:timescale/g, timescale)}`,
        data,
    };
};

export {
    formatBasicDataExportPayload,
    constructBasicDataPostConfig,
};
